<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            ref="filterModal"
        >
            <template v-slot:filters>
                <TitleTypeAccountFilter
                    ref="titleTypeAccountFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCodes="filterPayload.manCodes"
                    :hasSelectedALL="true"
                />
                <Textbox
                    v-if="mode === '1'"
                    name="customerName"
                    title="客戶姓名"
                    type="text"
                    v-model="filterPayload.customerName"
                    placeholder="請輸入客戶姓名"
                />
                <Textbox
                    v-if="mode === '1'"
                    name="productFilter"
                    title="商品名稱或代號"
                    type="text"
                    v-model="filterPayload.productFilter"
                    placeholder="請輸入商品名稱或代號"
                />
                <DateRangeTextbox
                    v-if="mode === '1'"
                    :hasLimit="true"
                    min="default"
                    max="today"
                    title="受理日"
                    v-model:startDate="filterPayload.receiveDateStart"
                    v-model:endDate="filterPayload.receiveDateEnd"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
// import _ from 'lodash'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'
import DateRangeTextbox from '@/components/DateRangeTextbox.vue'
import Textbox from '@/components/Textbox.vue'
import FilterModal from '@/containers/FilterModal.vue'
import { insuranceFilterTitles } from '@/assets/javascripts/tia/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'InsuranceFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        FilterModal,
        TitleTypeAccountFilter,
        DateRangeTextbox,
        Textbox
    },
    props: {
        page: {
            type: String,
            default: 'insurance'
        },
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        mode: {
            type: String,
            default: '1'
        }
    },
    methods: {
        resetFilter: function () {
            this.filterPayload = {
                manCodes: this.filterPayload?.manCodes,
                receiveDateStart: this.$formatDate(this.$getDate(-365)),
                receiveDateEnd: this.$formatDate(this.$getDate()),
                ...filterDefault.insurance
            }

            this.$refs.titleTypeAccountFilter.resetFilter()
            // across filter
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },

        getFilterData: async function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload[this.mode],
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload[this.mode] = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
                this.filterLog[this.mode] = this.$getFilterLog(
                    this.filterPayload
                )
            }
            this.hasCrossFilter = false
        }
    },
    watch: {
        'filterPayload.customerName': {
            handler() {
                if (!this.hasCrossFilter) this.filterPayload.customerCode = null
            }
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            dropdownOptions: {},
            perviousFilterPayload: {},
            filterTitles: insuranceFilterTitles,
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            filterOptions: [],
            hasCrossFilter: this.$hasCrossFilter()
        }
    },
    async mounted() {
        this.resetFilter()
        if (this.$route.name == 'TiaInsurance' && this.hasCrossFilter)
            await this.getFilterData()
    },
    async activated() {
        this.hasCrossFilter = this.$hasCrossFilter()
        if (this.hasCrossFilter) this.resetFilter()

        if (this.$route.name == 'TiaInsurance' && this.hasCrossFilter) {
            await this.getFilterData()
        }
    }
}
</script>

<style lang="scss" scoped>
.insStatus-filter {
    :deep(.checkbox-container) {
        padding-left: 1px;
    }
}
</style>
